import $ from 'jquery';

const mobile = () => {
  const products = $('.products-section-list-item');

  products.click((event) => {
    if (event.target.classList.contains('products-section-list-item-details-close')) {
      event.currentTarget.classList.remove('active');

      return;
    }

    event.currentTarget.classList.add('active');
  });
};

const desktop = () => {
  const list = $('.products-section-list');
  const products = $('.products-section-list-item');
  const productDetails = $('.products-section-list-item-details');
  let maxItemHeight = 0;

  list.find(':first-child').addClass('active');
  $('.products-section-item-details-desktop').find(':first-child').addClass('active');

  products.hover((event) => {
    products.removeClass('active');
    productDetails.removeClass('active');
    event.currentTarget.classList.add('active');
    $(`.products-section-list-item-details[data-product="${event.currentTarget.getAttribute('data-product')}"]`).addClass('active');
  });

  setTimeout(() => {
    products.each(function forEach() {
      const detailsHeight = $(this).find('.products-section-list-item-details').outerHeight();

      maxItemHeight = detailsHeight > maxItemHeight ? detailsHeight : maxItemHeight;
    });

    $('.products-section-item-details-desktop').css('height', `${maxItemHeight + 40}px`);
  }, 200);
};

const removeEventHandlers = () => {
  $('.products-section-list-item')
    .off()
    .removeClass('active');
  $('.products-section-list').css('margin-bottom', '');
};

const onOrientationChange = () => {
  removeEventHandlers();

  if ($(window).width() <= 991) {
    mobile();
  } else {
    desktop();
  }
};

export default () => {
  $(document).ready(() => {
    window.addEventListener('orientationchange', onOrientationChange);

    onOrientationChange();
  });
};
