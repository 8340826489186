import $ from 'jquery';

require('slick-carousel');

export default () => {
  const isRtl = $('html').attr('dir') === 'rtl';

  $('.hero-slider-slick').slick({
    rtl: isRtl,
    fade: true,
    speed: 500,
    infinite: false,
   });

    $('.hero-slider-arrow.arrow-prev').click(function () {
        $('.hero-slider .slick-arrow.slick-prev').triggerHandler('click');
    });

    $('.hero-slider-arrow.arrow-next').click(function () {
        $('.hero-slider .slick-arrow.slick-next').triggerHandler('click');
    });
};
