import $ from 'jquery';

import cardVideo from './card-video';
import cardImages from './card-images';

const numCardsPerPage = 6;

const appendCards = (section, skip, clearPreviousCards) => {
  const lang = $('html').attr('lang');
  const videos = section.find('[name="filters"][value="videos"]').is(':checked');
  const images = section.find('[name="filters"][value="images"]').is(':checked');
  const news = section.find('[name="filters"][value="news"]').is(':checked');
  const articles = section.find('[name="filters"][value="articles"]').is(':checked');
  const apiUrl = section.find('#media-api').val();

  return $.get(apiUrl, {
    videos,
    images,
    news,
    articles,
    lang,
    skip,
    take: numCardsPerPage
  }).done((data) => {
    const cardsContainer = section.find('[data-mediacol-container]');

    if (clearPreviousCards) {
      section.find('[data-mediacol]').remove();
    }
    if (data.cards != null)
    {
        data.cards.forEach((card) => {
            let cardHtml = $(`#media-center-template-${card.type}`).html();
            cardHtml = cardHtml.replace(/\[\[title\]\]/g, card.title)
              .replace(/\[\[coverImage\]\]/g, card.coverImage)
              .replace(/\[\[date\]\]/g, card.date)
              .replace(/\[\[linkUrl\]\]/g, card.linkUrl);
            if (card.type="image")
            {
                let relatedHtml = card.images.map(i => '<div class="images-carousel-slick-elem" style="background-image: url(\'' + i.url + '\');"></div>').join('');
                cardHtml = cardHtml.replace(/\[\[related\]\]/g, relatedHtml)
            }
            cardsContainer.append(cardHtml);
        });
    }

    cardVideo();
    cardImages();

    const totNumCards = section.find('[data-mediacol]').length;
    if (data.numCards > totNumCards) {
      section.find('.media-center-loadmore-row').addClass('active');
    } else {
      section.find('.media-center-loadmore-row').removeClass('active');
    }
  });
};

const recreateCards = (section) => {
  const sectionHeight = Math.ceil(section.height());
  section.css('min-height', `${sectionHeight}px`);
  section.addClass('loading');
  section.find('.media-center-loadmore-row').removeClass('active');

  const videos = section.find('[name="filters"][value="videos"]').is(':checked');
  const images = section.find('[name="filters"][value="images"]').is(':checked');
  const news = section.find('[name="filters"][value="news"]').is(':checked');
  const articles = section.find('[name="filters"][value="articles"]').is(':checked');

  const noTypeSelected = !videos && !images && !news && !articles;

  // delay for the CSS animation
  setTimeout(() => {
    if (noTypeSelected) {
      section.find('[data-mediacol]').remove();
      section.css('min-height', '0');
      section.removeClass('loading');
      return;
    }

    appendCards(section, 0, true)
      .always(() => {
        // delay for the CSS animation
        setTimeout(() => {
          section.css('min-height', '0');
          section.removeClass('loading');
        }, 200);
      });
  }, 200);
};

export default () => {

  $('.media-center-filters-button-all').click((event) => {
    const buttonAll = $(event.currentTarget);
    const section = buttonAll.closest('.media-center');
    const checkboxes = section.find('.media-center-filters-input');
    buttonAll.toggleClass('active');

    const isSelectedAll = buttonAll.hasClass('active');
    checkboxes.prop('checked', isSelectedAll);

    recreateCards(section);
  });

  $('.media-center-filters-input').on('change', (event) => {
    const checkbox = $(event.currentTarget);
    const section = checkbox.closest('.media-center');
    const buttonAll = section.find('.media-center-filters-button-all');
    const checkboxes = section.find('.media-center-filters-input');
    const allCheckboxesChecked = checkboxes.toArray().every(checkboxesElem => $(checkboxesElem).is(':checked'));

    if (checkbox.is(':checked') === false &&
            buttonAll.hasClass('active')) {
      buttonAll.removeClass('active');
    }

    if (allCheckboxesChecked &&
            !buttonAll.hasClass('active')) {
      buttonAll.addClass('active');
    }

    recreateCards(section);
  });

  $('.media-center-loadmore').click((event) => {
    const button = $(event.currentTarget);
    const section = button.closest('.media-center');
    const totNumCards = section.find('[data-mediacol]').length;

    appendCards(section, totNumCards, false);
  });
};
