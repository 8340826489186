import $ from 'jquery';
import global from './global';
import header from './components/header';
import footer from './components/footer';
import servicesSection from './components/services-section';
import cardQuotes from './components/card-quotes';
import cardVideo from './components/card-video';
import cardImages from './components/card-images';
import cardNewsletter from './components/card-newsletter';
import heroSlider from './components/hero-slider';
import promotionSectionSlider from './components/promotion-section-slider';
import productsSection from './components/products-section';
import genericForm from './components/generic-form';
import mediaCenter from './components/media-center';
import mediaCenterImages from './components/media-center-images';
import skipToContent from './components/skip-to-content';

import instagramStoriesSlider from './components/instagram-stories-slider';

import readMore from './read-more';

require('./quick');

readMore();
heroSlider();
cardQuotes();
promotionSectionSlider();
servicesSection();
cardImages();
skipToContent();

$(document).ready(() => {
  global();
  header();
  footer();
  cardVideo();
  cardNewsletter();
  productsSection();
  genericForm();
  mediaCenter();
  mediaCenterImages();
    instagramStoriesSlider();
});
