import $ from 'jquery';
import queryString from 'query-string';

const search = () => {
  const headerWrapper = $('.header-wrapper');
  const searchButton = $('.header-rightSide-search');
  const closeSearchButton = $('.search-close-button');
  const searchInput = $('.search-input');

  // Toggle search
  const openSearch = () => {
    headerWrapper.addClass('search-open');
    searchInput.val('');
    searchInput.focus();
  };
  const closeSearch = () => {
    headerWrapper.removeClass('search-open');
    headerWrapper.removeClass('search-with-results');
    searchInput.blur();
  };

  searchButton.click(openSearch);
  closeSearchButton.click(closeSearch);

  $(document).on('keyup', (event) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.key === 'Escape') {
      closeSearch();
    }

    event.preventDefault();
  });

  // Add search term to the html from the quey parameter
  if (/search/.test(document.location.pathname)) {
    $('.search-results-term-text')
      .html(queryString.parse(document.location.search).term);
    // TODO Highlight the search term in the search results
  }
};

const navigation = () => {
  $(`a[href="${document.location.pathname}"]`)
    .addClass('active');
};

const subNavigation = () => {
  const secondaryNavigation = $('.header-navList-secondary');
  const itemClassname = 'header-navList-item';
  const itemsWithSubNavigation = $(`.${itemClassname}-hasSubNavigation`);
  const itemWithSubNavigationOpenClassname = `${itemClassname}-subNavigation-open`;

  // Show sub navigation of an item
  itemsWithSubNavigation.on('mouseover', (event) => {
    itemsWithSubNavigation.removeClass(itemWithSubNavigationOpenClassname);
    $(event.currentTarget).addClass(itemWithSubNavigationOpenClassname);
  });

  // Support wide touch screens
  itemsWithSubNavigation.on('touchstart', (event) => {
    if (event.target.classList.contains(itemClassname)) {
      $(event.currentTarget).toggleClass(itemWithSubNavigationOpenClassname);
    }
  });

  // Close all sub navigation when hovering out of the navigation
  secondaryNavigation.on('mouseout', () => {
    itemsWithSubNavigation.removeClass(itemWithSubNavigationOpenClassname);
  });
};

const mobileNavigation = () => {
  const mobileNavigationToggle = $('.mobile-navigation-toggle');
  const mobileNavigationElement = $('.mobile-navigation');
  const itemsWithSubNavigation = $('.mobile-navigation-secondary-item-hasSubNavigation');
  const subNavigationItem = $('.mobile-navigation-subNavigation-item');
  const subNavigationBack = $('.mobile-navigation-subNavigation-item-title');
  const itemSubNavigationOpenClassname = 'mobile-navigation-subNavigation-item-open';
  let scrollPosition = window.scrollY;

  mobileNavigationToggle.click(() => {
    if ($('html').hasClass('block-scroll')) {
      // Block page scroll
      $('html, body').removeClass('block-scroll');
      window.scrollTo(0, scrollPosition);
    } else {
      scrollPosition = window.scrollY;
      mobileNavigationElement.one('transitionend webkitTransitionEnd', () => {
        // Block page scroll
        $('html, body').addClass('block-scroll');
      });
    }

    mobileNavigationToggle.toggleClass('mobile-navigation-toggle-open');
    mobileNavigationElement.toggleClass('mobile-navigation-open');
    mobileNavigationElement.removeClass('mobile-navigation-subNavigation-open');
    subNavigationItem.removeClass(itemSubNavigationOpenClassname);
  });

  itemsWithSubNavigation.click((event) => {
    const subNavigationKey = $(event.currentTarget).attr('data-subnavigation');

    mobileNavigationElement.toggleClass('mobile-navigation-subNavigation-open');
    subNavigationItem.filter(`[data-subnavigation=${subNavigationKey}]`).toggleClass(itemSubNavigationOpenClassname);
  });

  subNavigationBack.click(() => {
    mobileNavigationElement.removeClass('mobile-navigation-subNavigation-open');
    subNavigationItem.removeClass(itemSubNavigationOpenClassname);
  });
};

export default () => {
  search();
  navigation();
  subNavigation();
  mobileNavigation();
};
