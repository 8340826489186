import $ from 'jquery';

require('magnific-popup');

export default () => {
  $('.card-video:not([data-initialized]) .card-video-button').click((event) => {
    const button = $(event.currentTarget);
    const section = button.closest('.card-video');
    const popup = section.find('.card-video-popup');
    const video = section.find('.card-video-popup-video').get(0);
    const closeButton = section.find('.card-video-popup-close');

    $.magnificPopup.open({
      items: {
        src: popup,
        type: 'inline'
      },
      callbacks: {
        open: () => {
          video.play();
        },
        close: () => {
          video.pause();
          video.currentTime = 0;
        }
      }
    });

    closeButton.click((closeButtonEvent) => {
      const closeButtonPopup = $(closeButtonEvent.currentTarget).closest('.card-video-popup');
      const mfpCloseButton = closeButtonPopup.find('.mfp-close');

      mfpCloseButton.click();
    });
    });


  $('.card-video').attr('data-initialized', 'true');
};
