import $ from 'jquery';

export default () => {
  const setup = () => {
    const elements = $('.vertical-ellipsis');

    elements
      .off()
      .removeClass('vertical-ellipsis-overflow')
      .find('*')
      .css('max-height', 'none');

    if ($(window).width() <= 991) {
      elements.css('visibility', 'visible');
      return;
    }

    setTimeout(() => {
      elements.each(function forEach() {
        const element = $(this).find('*');
        const maxLines = parseInt($(this).attr('data-max-lines'), 10);
        const elementLineHeight = parseInt(element.css('line-height').split('px')[0], 10);
        const elementLines = Math.round(element.height() / elementLineHeight);
        const maxHeight = elementLineHeight * maxLines;
        const height = element.height();

        if (elementLines - 1 > maxLines) {
          $(this).addClass('vertical-ellipsis-overflow');
          element.css('max-height', maxHeight);
          $(this).on('click', (event) => {
            $(event.currentTarget).find('*').css('max-height', height);
            $(event.currentTarget).removeClass('vertical-ellipsis-overflow');
          });
        }

        element.css('visibility', 'visible');
      });
    }, 200);
  };

  $(document).ready(() => {
    window.addEventListener('orientationchange', () => {
      $(document).ready(setup);
    });
    setup();
  });
};
