import $ from 'jquery';

require('magnific-popup');
require('slick-carousel');

let slickElems = null;

const initPopupSlick = (slickIndex) => {
  const isRtl = $('html').attr('dir') === 'rtl';

  if (slickElems) {
    slickElems.slick('slickGoTo', slickIndex);
    return;
  }

  slickElems = $('.media-center-images:not([data-initialized]) .images-carousel-slick');

  slickElems.on('init', (event, slick) => {
    const slides = slick.$slides
      .map((index, slide) => $(slide).find('.images-carousel-slick-elem'));
    const dots = slick.$dots.find('button');

    slides.each((index, slide) => {
      const dot = $(dots.get(index));
      dot.css('background-image', slide.css('background-image'));
    });

    const slickedSlides = slick.$slider.find('.slick-slide');
    slickedSlides.on('click', (slideEvent) => {
      const clickedSlide = $(slideEvent.currentTarget);

      if (clickedSlide.hasClass('slick-active')) {
        return;
      }
      if (clickedSlide.hasClass('slick-cloned')) {
        slick.slickGoTo(0);
        return;
      }

      const slideIndex = clickedSlide.attr('data-slick-index');

      slick.slickGoTo(parseInt(slideIndex, 10));
    });
  });

  slickElems.slick({
    rtl: isRtl,
    dots: true,
    initialSlide: parseInt(slickIndex, 10)
  });

  $('.media-center-images').attr('data-initialized', 'true');
};

const handleImagesPopup = () => {
  $('.media-center-images:not([data-initialized]) .media-center-images-button').click((event) => {
    const button = $(event.currentTarget);
    const section = button.closest('.media-center-images');
    const popup = section.find('.images-carousel');
    const closeButton = section.find('.images-carousel-close');
    const slickIndex = button.closest('.slick-slide').attr('data-slick-index');

    $.magnificPopup.open({
      items: {
        src: popup,
        type: 'inline'
      },
      callbacks: {
        beforeOpen: () => {
          $('html').addClass('images-carousel-open');
          initPopupSlick(slickIndex);
        },
        afterClose: () => {
          $('html').removeClass('images-carousel-open');
        }
      }
    });

    closeButton.click((closeButtonEvent) => {
      const closeButtonPopup = $(closeButtonEvent.currentTarget).closest('.images-carousel');
      const mfpCloseButton = closeButtonPopup.find('.mfp-close');

      mfpCloseButton.click();
    });
  });
};

export default () => {
  const isRtl = $('html').attr('dir') === 'rtl';

  $('.media-center-images-slick').slick({
    rtl: isRtl,
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          rtl: isRtl,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }]
  });

  handleImagesPopup();
};
