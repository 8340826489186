import $ from 'jquery';

import isRtl from '../utils/is-rtl';

export default () => {
    $('.instagram-stories').slick({
        autoplay: false,
        dots: false,
        arrows: true,
        slidesPerRow: 3,
        appendArrows: $('.instagram-stories-section-header-buttons'),
        prevArrow: "<svg class='instagram-stories-section-header-buttons-arrow instagram-stories-section-header-buttons-arrow-rotate' viewBox='0 0 16 35' xmlns='http://www.w3.org/2000/svg'><path d='M12.226 25.661c4.401-3.972 4.626-10.873.525-15.433l.002-.002L3.658.13C1.373 2.326.72 5.606 1.716 8.634l5.742 6.36c1.368 1.526 1.29 3.833-.186 5.165l-5.953 5.36c-1.21 2.886-.786 6.234 1.346 8.752l.01-.008-.005.006 9.556-8.608z' fill='#CE003C' fill-rule='evenodd'/></svg>",
        nextArrow: "<svg class='instagram-stories-section-header-buttons-arrow' viewBox='0 0 16 35' xmlns='http://www.w3.org/2000/svg'><path d='M12.226 25.661c4.401-3.972 4.626-10.873.525-15.433l.002-.002L3.658.13C1.373 2.326.72 5.606 1.716 8.634l5.742 6.36c1.368 1.526 1.29 3.833-.186 5.165l-5.953 5.36c-1.21 2.886-.786 6.234 1.346 8.752l.01-.008-.005.006 9.556-8.608z' fill='#CE003C' fill-rule='evenodd'/></svg>",
        responsive: [
            // Phone 
            {
                breakpoint: 767,
                settings: {
                    slidesPerRow: 2
                }
            }
        ],
        rtl: isRtl()
    });
};
