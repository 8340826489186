import $ from 'jquery';

require('magnific-popup');
require('slick-carousel');

const initSlick = (section) => {
    const isRtl = $('html').attr('dir') === 'rtl';
    if (!section.attr('data-initialized'))
    {
        const slickElems = section.find('.images-carousel-slick');
        slickElems.on('init', (event, slick) => {
            const slides = slick.$slides != null ? slick.$slides
              .map((index, slide) => $(slide).find('.images-carousel-slick-elem')) : null;

            if (slides != null && slick.$dots) {
                const dots = slick.$dots.find('button');
                slides.each((index, slide) => {
                    const dot = $(dots.get(index));
                    dot.css('background-image', slide.css('background-image'));
                });
            }

            const slickedSlides = slick.$slider.find('.slick-slide');
            slickedSlides.on('click', (slideEvent) => {
                const clickedSlide = $(slideEvent.currentTarget);

                if (clickedSlide.hasClass('slick-active')) {
                    return;
                }
                if (clickedSlide.hasClass('slick-cloned')) {
                    slick.slickGoTo(0);
                    return;
                }

                const slideIndex = clickedSlide.attr('data-slick-index');

                slick.slickGoTo(slideIndex);
            });
        });
        slickElems.slick({         
            rtl: isRtl,
            dots: true
        });

        section.attr('data-initialized', 'true');
    }
  
};

export default () => {
    $('.card-images:not([data-initialized]) .card-images-button').click((event) => {
    const button = $(event.currentTarget);
    const section = button.closest('.card-images');
    const popup = section.find('.images-carousel');
    const closeButton = section.find('.images-carousel-close');

    $.magnificPopup.open({
      items: {
        src: popup,
        type: 'inline'
      },
      callbacks: {
        beforeOpen: () => {
            $('html').addClass('images-carousel-open');
            initSlick(section);
        },
        open: () => {
        },
        afterClose: () => {
          $('html').removeClass('images-carousel-open');
        }
      }
    });

    closeButton.click((closeButtonEvent) => {
      const closeButtonPopup = $(closeButtonEvent.currentTarget).closest('.images-carousel');
      const mfpCloseButton = closeButtonPopup.find('.mfp-close');

      mfpCloseButton.click();
    });
  });
};
