import $ from 'jquery';

require('slick-carousel');

export default () => {
  const isRtl = $('html').attr('dir') === 'rtl';

  $('.services-section-slick').slick({
    dots: true,
    arrows: false,
    fade: true,
    swipe: false,
    rtl: isRtl
  });

  $('.services-section-nav-button').click((event) => {
    const button = $(event.currentTarget);
    const elem = button.closest('.services-section-nav-list-elem');
    const list = button.closest('.services-section-nav-list');
    const nav = button.closest('.services-section-nav');
    const elemIndex = $(list.find('.services-section-nav-list-elem')).index($(elem));
    const section = button.closest('.services-section');
    const slickDotsButtons = $(section).find('.slick-dots li button');

    if (elemIndex > slickDotsButtons.length - 1) {
      return;
    }

    $(slickDotsButtons[elemIndex]).click();
    $(nav).find('.services-section-nav-list-elem').removeClass('active');
    $(elem).addClass('active');
  });
};
