import $ from 'jquery';

require('smoothscroll-polyfill').polyfill();

export default () => {
  $('.skip-to-content').click((event) => {
    event.preventDefault();
    const button = $(event.currentTarget);
    const linkTargetId = button.attr('href');
    // eslint-disable-next-line no-undef
    const isDevice = $(window).width() < 992;
    const linkTargetTop = $(linkTargetId).offset().top;
    const scrollPosition = isDevice ? linkTargetTop - 103 : linkTargetTop;
    // eslint-disable-next-line no-undef
    window.scroll({ top: scrollPosition, left: 0, behavior: 'smooth' });
  });
};
