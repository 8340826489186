import $ from 'jquery';

require('smoothscroll-polyfill').polyfill();

const focusfirstErrorField = (form) => {
  const errorFields = form.find('[data-form-container-wrapper].error [data-form-container-input]');
  if (!errorFields.length) {
    return;
  }

  const firstErrorField = errorFields.first();
  firstErrorField.focus();

  // eslint-disable-next-line no-undef
  const isDevice = $(window).width() < 992;
  const firstErrorFieldTop = firstErrorField.offset().top;
  const scrollPosition = isDevice ? firstErrorFieldTop - 103 : firstErrorFieldTop - 156;
  // eslint-disable-next-line no-undef
  window.scroll({ top: scrollPosition, left: 0, behavior: 'smooth' });
};

export default () => {
  $('form.generic-form').submit((event) => {
    const form = $(event.currentTarget);

    if (form.validate(null, form)) {
    } else {
      event.preventDefault();
      focusfirstErrorField(form);
    }
  });
};
