import $ from 'jquery';

require('slick-carousel');

export default () => {
  const isRtl = $('html').attr('dir') === 'rtl';

  $('.card-quotes-slick').slick({
      rtl: isRtl,
      infinite: false,
  });

    $('.card-quotes-arrow.arrow-prev').click(function () {
        $('.card-quotes .slick-arrow.slick-prev').triggerHandler('click');
    });

    $('.card-quotes-arrow.arrow-next').click(function () {
        $('.card-quotes .slick-arrow.slick-next').triggerHandler('click');
    });
};
