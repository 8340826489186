import $ from 'jquery';

export default () => {
    $('form.card-newsletter-form').submit((event) => {
        const form = $(event.currentTarget);
        const submitBtn = form.find('button[type="submit"]');
        event.preventDefault();
        if (form.validate(null, form)) {
            submitBtn.attr("disabled", "disabled");
            var datastring = form.serialize();
            $.post(window.location.href, datastring, function (data) {
                var msgHtml = $(data).find('.card-newsletter-form').html()
                console.log(msgHtml);
                form.html(msgHtml);
            }).fail(function () {
                form.html('<div class="newsletter-error-message">Error occured</div>');
            });
        }
    });
};
