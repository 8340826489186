import $ from 'jquery';

require('slick-carousel');

export default () => {
  const isRtl = $('html').attr('dir') === 'rtl';

  $('.promotion-section-slider-slick').slick({
    rtl: isRtl,
    fade: true,
    speed: 500
  });

  $('.promotion-section-slider-arrow').click((event) => {
    const button = $(event.currentTarget);
    const section = button.closest('.promotion-section-slider');
    const arrowPrev = $(section).find('.slick-arrow.slick-prev');
    const arrowNext = $(section).find('.slick-arrow.slick-next');
    const isButtonPrev = button.hasClass('arrow-prev');

    if (isButtonPrev) {
      arrowPrev.click();
    } else {
      arrowNext.click();
    }
  });
};
